define("discourse/plugins/discourse-translator/discourse/templates/connectors/post-after-cooked/translate", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{translated-post post=this}}
  
  */
  {
    "id": "qtRpETKe",
    "block": "[[[1,[28,[35,0],null,[[\"post\"],[[30,0]]]]],[1,\"\\n\"]],[],false,[\"translated-post\"]]",
    "moduleName": "discourse/plugins/discourse-translator/discourse/templates/connectors/post-after-cooked/translate.hbs",
    "isStrictMode": false
  });
});